import React from 'react';

export default function ContinentItemList( props ) {
    return (
        <>

        
        <section className="text-gray-600 body-font  content-center" >

            <div className="container px-5 py-24 mx-auto max-w-3xl lg:max-w-3xl xl:max-w-screen-xl">
            <ol className="flex flex-wrap -m-4" >
            
      


            {props.continentItemListData.map((it,index)=>(
                
                <li className="p-4 w-full md:w-1/2 xl:w-1/3" key={index}>
                    <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden relative">
                    <img className="md:h-80 w-full object-center" src={it.image} alt={props.continentExplanation.continent_ko+" IMAGE "+ index}/>
                        <div className="p-6 text-left">


                        <span className="bg-orange-500 text-white text-sm font-semibold px-2.5 py-1 rounded">{index + 1}. {it.city_ko}</span>
                        <h2 className="text-lg font-medium text-gray-900 mt-2.5">{it.title}</h2>

                        <p className="text-sm font-medium text-gray-400 mt-2">INFO :{it.subinfo}</p>
                        <div className="mb-12">
                            
                        </div>



                        <div className="absolute w-full bottom-0 left-0 flex  bg-yellow-100 rounded p-1">

                     

                            <svg className="my-auto ml-2 mr-1" version="1.0" xmlns="http://www.w3.org/2000/svg"  
                            width="48.000000pt" height="24.000000pt" 
                            viewBox="0 0 300.000000 160.000000"  preserveAspectRatio="xMidYMid meet">  
                            <g transform="translate(0.000000,167.000000) scale(0.100000,-0.100000)" fill="#ff5723" stroke="none"> 
                            <path d="M70 835 c0 -397 -2 -388 55 -343 51 40 55 64 55 348 0 288 -5 316 -59 344 -16 9 -35 16 -41 16 -7 0 -10 -117 -10 -365z"/> 
                            <path d="M710 835 l0 -365 180 0 c172 0 180 1 180 19 0 32 -42 71 -85 81 -22 5 -68 10 -102 10 l-63 0 0 255 c0 205 -3 262 -15 293 -14 35 -57 72 -84 72 -8 0 -11 -107 -11 -365z"/> <path d="M1249 1187 c-106 -30 -184 -100 -236 -213 -22 -48 -27 -74 -27 -139 -1 -70 3 -89 31 -147 39 -82 100 -145 179 -184 53 -26 69 -29 154 -29 85 0 101 3 155 29 33 17 81 51 108 78 26 26 50 48 52 48 3 0 23 -20 46 -45 24 -26 70 -59 108 -78 59 -29 74 -32 156 -32 78 0 98 4 153 29 128 59 212 188 212 325 0 252 -227 425 -466 356 -67 -19 -100 -40 -165 -102 l-46 -45 -52 52 c-68 68 -143 101 -241 106 -44 2 -94 -1 -121 -9z m218 -123 c90 -43 138 -126 137 -234 -1 -101 -51 -183 -140 -226 -46 -23 -64 -26 -127 -22 -96 4 -158 40 -204 117 -95 158 -9 357 168 390 59 11 104 4 166 -25z m618 6 c51 -22 103 -74 126 -125 21 -46 25 -139 7 -196 -16 -54 -71 -115 -131 -146 -45 -22 -60 -25 -128 -21 -68 4 -82 8 -125 39 -70 51 -107 115 -112 195 -13 202 178 336 363 254z"/> <path d="M2400 835 c0 -408 -3 -395 68 -331 l37 34 3 274 c4 306 -1 338 -51 368 -18 11 -38 20 -44 20 -10 0 -13 -80 -13 -365z"/> <path d="M387 1012 l-177 -177 179 -179 c99 -99 184 -176 189 -172 6 4 14 22 17 40 10 50 -14 87 -133 208 l-104 107 115 114 c88 88 117 123 122 149 6 33 -8 88 -22 88 -5 0 -88 -80 -186 -178z"/> <path d="M2722 1017 c-94 -95 -172 -176 -172 -181 0 -5 79 -88 176 -185 165 -166 177 -175 190 -157 18 25 18 70 0 104 -8 15 -62 74 -120 132 l-106 105 109 110 c115 117 131 138 131 180 0 28 -16 65 -28 65 -4 0 -85 -78 -180 -173z"/> 
                            </g> 
                            </svg> 

                            <span className="title-font font-medium text-sm sm:text-base text-gray-900 my-auto">가격 : {Number(it.price).toLocaleString("ko-KR", {style:"currency", currency:"KRW"})}</span>
                            <button className="flex ml-auto mr-1 text-white text-sm sm:text-base my-auto bg-orange-500 border-0 py-2 px-2  sm:px-4 focus:outline-none hover:bg-blue-600 rounded"
                             onClick={()=>{window.open(`https://affiliate.klook.com/redirect?aid=10526&aff_adid=754524&k_site=${it.url}`)}}
                            >자세히보기</button>

                        </div>







                        </div>


                    </div>
                </li>
            ))}


            

            </ol>
            </div>
            </section>
        </>
    );
}

