import React from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import {useQuery } from '@tanstack/react-query' 
import SeoHelmet from '../components/metaTag/SeoHelmet';
import CountryItemList from '../components/items/CountryItemList';
import AdsComponent from '../components/ads/AdsComponent';

export default function CountryPage() {
    let {countryCode} = useParams();
    
    countryCode = countryCode ? countryCode : 'korea' ;


    let countryExplanationResult = useQuery(['countryExplanationResult'+countryCode], () => 
        axios.get('https://travel.ryokonote.com/api/country-explanation.php?country_code='+countryCode)
        .then((list) =>{
         
            return list.data
         }),
         {staleTime : 500000}
    )


    let countryCityListResult = useQuery(['countryCityListResult'+countryCode], () => 
        axios.get('https://travel.ryokonote.com/api/country-get-city-list.php?country_code='+countryCode)
        .then((list) =>{
         
            return list.data
         }),
         {staleTime : 500000}
    )



    let countryItemResult = useQuery(['countryItemResult'+countryCode], () => 
        axios.get('https://travel.ryokonote.com/api/country-get-item.php?country_code='+countryCode)
        .then((list) =>{
   
            return list.data
         }),
         {staleTime : 500000}
    )
 

  

    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;


    return (
        <>

            <>

                

            

                {countryExplanationResult.isLoading &&  
                    <>
                    
                    <SeoHelmet
                        title={`Loading...`}
                        description={`Loading...`}
                        keywords={`Loading...`}
                        imgsrc={`https://travel.ryokonote.com/image/logo/travelnote-image.png`}
                    />

                    <div className='h-screen flex mx-2'>
                       
                       <div type="button" className=" justify-center m-auto py-2 px-4 flex justify-center items-center  bg-black hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg max-w-md">
                           <svg width="20" height="20" fill="currentColor" className="mr-2 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                               <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
                               </path>
                           </svg>
                           로딩중입니다.
                       </div>

                    </div>

                    </>
                }
                {countryExplanationResult.error &&<div>Loading error</div>}
                {countryExplanationResult.data &&(
                    <>
                        
                    
                    


                    <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                                <div className="rounded-lg bg-gray-100 px-4 py-6 md:py-8 lg:py-12">
                                <p className="mb-2 text-center font-semibold text-indigo-500 md:mb-3 lg:text-lg">{countryExplanationResult.data[0].country_ko} 여행</p>

                                <h1 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">
                                    {countryExplanationResult.data[0].country_ko}여행 추천 여행지, 준비물, 추천 엑티비티, 꿀팁, 후기 및 리뷰, 방문시기 총정리 | {currentYear}년 {currentMonth}월
                                </h1>

                                
                                </div>
                            </div>
                    </div>


                    <AdsComponent dataAdSlot='6007352998' />



                    <div>
                        
                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-md px-4">
                                <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6">{countryExplanationResult.data[0].country_ko}의 추천 여행지역은?</h2>

                                <p className="mb-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {countryExplanationResult.data[0].travel_recommend_destination.replaceAll('@@', '\n')}
                                </p>


                           
                            </div>
                        </div>




                       

            

                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-md px-4">
                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{countryExplanationResult.data[0].country_ko}에서 할 것 또는 추천 엑티비티는?</h2>

                                <p className="m-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {countryExplanationResult.data[0].travel_activity.replaceAll('@@', '\n')}
                                </p>
                                
                            </div>
                        </div>


                    </div>

                    
                    </>




                    

                )}
            </>



            {countryExplanationResult.data && countryItemResult.data && (
                                <>

                                    <SeoHelmet
                                        title={`${countryExplanationResult.data[0].country_ko} 추천 여행지, 엑티비티, 리뷰, 준비물 총정리 ${currentYear}년 ${currentMonth}월`}
                                        description={`${countryExplanationResult.data[0].travel_recommend_destination.replaceAll('@@', '').substring(0, 79)}`}
                                        keywords={`${countryExplanationResult.data[0].country_ko}`}
                                        imgsrc={`${countryItemResult.data[0].image}`}
                                    />

                                <div className="bg-white py-3">
                                            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                                                <div className="rounded-lg bg-gray-100 px-4 py-6 md:py-8 lg:py-12">
                                                <p className="mb-2 text-center font-semibold text-indigo-500 md:mb-3 lg:text-lg">Activity</p>

                                                <h3 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">{countryExplanationResult.data[0].country_ko}의 추천 여행 엑티비티</h3>

                                                <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">{countryExplanationResult.data[0].country_ko}의 다양한 여행상품을 확인해보세요.</p>
                                                </div>
                                            </div>
                                    </div>
                                
                                    <CountryItemList goReverse="no" countryExplanation={countryExplanationResult.data[0]} countryItemListData={countryItemResult.data}/>
                                </>
                                
                    )
                }

                    {countryExplanationResult.data &&(
                    <>
                    
                        
                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-md px-4">
                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{countryExplanationResult.data[0].country_ko}의 여행을 위해 준비하면 좋은 것은?</h2>

                                <p className="m-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {countryExplanationResult.data[0].travel_need_thing.replaceAll('@@', '\n')}
                                </p>
                                
                            </div>
                        </div>



                        


                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-md px-4">
                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{countryExplanationResult.data[0].country_ko}에 방문하기 좋은 시기는?</h2>

                                <p className="m-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {countryExplanationResult.data[0].travel_good_time.replaceAll('@@', '\n')}
                                </p>
                                
                            </div>
                        </div>


                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-md px-4">
                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{countryExplanationResult.data[0].country_ko}의 여행팁을 알려주세요.</h2>

                                <p className="m-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {countryExplanationResult.data[0].travel_tip.replaceAll('@@', '\n')}
                                </p>
                                
                            </div>
                        </div>



                        {countryExplanationResult.data && countryItemResult.data && (
                                <>

                                <div className="bg-white py-3">
                                            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                                                <div className="rounded-lg bg-gray-100 px-4 py-6 md:py-8 lg:py-12">
                                                <p className="mb-2 text-center font-semibold text-indigo-500 md:mb-3 lg:text-lg">Activity</p>

                                                <h3 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">{countryExplanationResult.data[0].country_ko}의 추천 여행 엑티비티</h3>

                                                <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">{countryExplanationResult.data[0].country_ko} 자유여행을 떠나시나요?</p>
                                                </div>
                                            </div>
                                    </div>
                                
                                    <CountryItemList goReverse="reverse" countryExplanation={countryExplanationResult.data[0]} countryItemListData={countryItemResult.data}/>
                                </>
                                        
                            )
                        }

                        

                        

                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-md px-4">
                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{countryExplanationResult.data[0].country_ko}의 추천 음식</h2>

                                <p className="m-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {countryExplanationResult.data[0].travel_watch_out.replaceAll('@@', '\n')}
                                </p>
                                
                            </div>
                        </div>


                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-md px-4">
                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{countryExplanationResult.data[0].country_ko} 리뷰 및 후기</h2>

                                <p className="m-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {countryExplanationResult.data[0].travel_review.replaceAll('@@', '\n')}
                                </p>
                                
                            </div>
                        </div>


                    </>
                                
                    )}







           



        {countryExplanationResult.data && countryCityListResult.data &&(

        <>
     
            
            <div className="bg-white py-3">
                    <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                        <div className="rounded-lg bg-gray-100 px-4 py-6 md:py-8 lg:py-12">
                        <p className="mb-2 text-center font-semibold text-indigo-500 md:mb-3 lg:text-lg">Country</p>

                        <h3 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">{countryExplanationResult.data[0].country_ko}의 어느 도시로 여행을 가시나요?</h3>

                        <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">지역을 선택하세요.</p>
                        </div>
                    </div>
            </div>


            <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 px-2 my-10">
                {countryCityListResult.data.map((it,index)=>(
                    

                    <Link to={"/city/"+it.city_code} key={it.city_code} className="p-2 sm:w-1/2 w-full hover:bg-rose-700 hover:text-white">
                        <div className="bg-white-100 border-2 rounded flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-pink-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 20 20">
                                <path fill="none" d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path>
                            </svg>
                            <span className="title-font font-medium">{it.city_ko}</span>
                        </div>
                    </Link>
                    
                ))}
            </div>

        </>
        )}

         
                
                

        </>
    );
}