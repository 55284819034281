import './App.css';
import { Routes, Route } from 'react-router-dom';
import Page404 from './routes/Page404';
import HeaderNav from './components/HeaderNav';
import SiteHomePage from './routes/SiteHomePage';
import Footer from './components/Footer'
import SeoHelmet from './components/metaTag/SeoHelmet'
import ContinentPage from './routes/ContinentPage';
import CountryPage from './routes/CountryPage';
import CityPage from './routes/CityPage';

function App() {
  return (
    <>
    <SeoHelmet/>
    
      <HeaderNav/>
    

      <Routes>
        <Route path='/' element={<SiteHomePage/>}/>
        <Route path='*' element={<Page404/>}/>
        <Route path='/continent/:continentCode' element={<ContinentPage/>}/>
        <Route path='/country/:countryCode' element={<CountryPage/>}/>
        <Route path='/city/:cityCode' element={<CityPage/>}/>
      </Routes>
      
      <Footer/>
    </>
  );
}

export default App;
