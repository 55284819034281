import React from 'react';
import { Link } from 'react-router-dom';
import SeoHelmet from '../components/metaTag/SeoHelmet';

export default function SiteHomePage() {

    let continentCategoryList = [{"land_id":"20","continent_ko":"남아시아","continent_code":"southasia"},{"land_id":"51","continent_ko":"동남아시아","continent_code":"southeastasia"},{"land_id":"111","continent_ko":"동아시아","continent_code":"eastasia"},{"land_id":"41","continent_ko":"북아메리카","continent_code":"northamerica"},{"land_id":"14","continent_ko":"오세아니아","continent_code":"oceania"},{"land_id":"136","continent_ko":"유럽","continent_code":"europe"},{"land_id":"23","continent_ko":"중동","continent_code":"middleeast"}];


    return (
        <div>       
                <SeoHelmet
                    title={`트래블노트`}
                    description={`트래블노트는 세계 각 나라의 아름다운 여행지를 소개합니다. 우리는 여행을 사랑하며, 다양한 문화와 경험을 탐험하는 것을 즐깁니다.`}
                    keywords={'여행,리뷰,해외여행'}
                    imgsrc={`https://travel.ryokonote.com/image/logo/travelnote-image.png`}
                />


                

                <div className="bg-white py-3">
                    <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                        <div className="rounded-lg bg-gray-100 px-4 py-6 md:py-8 lg:py-12">
                        <p className="mb-2 text-center font-semibold text-indigo-500 md:mb-3 lg:text-lg">Travel</p>

                        <h3 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">어느 대륙으로 여행을 가시나요?</h3>

                        <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">대륙을 선택하세요.</p>
                        </div>
                    </div>
                </div>

  
                {
                    <>
                        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 px-2 my-10">
                            {continentCategoryList.map((it,index)=>(
                                

                                <Link to={"/continent/"+it.continent_code} key={it.continent_code} className="p-2 sm:w-1/2 w-full hover:bg-rose-700 hover:text-white">
                                    <div className="bg-white-100 border-2 rounded flex p-4 h-full items-center">
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-pink-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 20 20">
                                            <path fill="none" d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path>
                                        </svg>
                                        <span className="title-font font-medium">{it.continent_ko}</span>
                                    </div>
                                </Link>

                                
                                
                            ))}
                         </div>
                    </>
                }


                <div className="bg-white py-6 ">
                    <div className="mx-auto max-w-screen-lg px-4 md:px-8">
                        <div className="mx-auto flex  flex-col items-center text-center">
                            <p className="mb-4 font-semibold text-indigo-500 md:mb-6 md:text-lg xl:text-xl">Excited To Introduce</p>

                            <h1 className="mb-8 text-base font-bold text-black  md:mb-12 ">
                            트래블노트는 세계 각 나라의 아름다운 여행지를 소개합니다.<br/>
                            우리는 여행을 사랑하며, 다양한 문화와 경험을 탐험하는 것을 즐깁니다. <br/>
                            여행한다는 것은 새로운 경험과 기억을 만드는 특별한 시간입니다.<br/>
                            트래블노트와 함께 더욱 풍부하고 의미 있는 여행이 되셨으면 좋겠습니다.<br/>
                            함께 여행을 떠나고 세계 각지의 아름다움을 발견하는 재미를 함께 누리시길 바랍니다.
                            </h1>

                
                        </div>
                    </div>
                </div>

   


        </div>
    );
}

