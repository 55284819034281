import React from 'react';

import { Link } from 'react-router-dom';
import AdsComponent from './ads/AdsComponent';


export default function Footer() {

    let continentCategoryList = [{"land_id":"20","continent_ko":"남아시아","continent_code":"southasia"},{"land_id":"51","continent_ko":"동남아시아","continent_code":"southeastasia"},{"land_id":"111","continent_ko":"동아시아","continent_code":"eastasia"},{"land_id":"41","continent_ko":"북아메리카","continent_code":"northamerica"},{"land_id":"14","continent_ko":"오세아니아","continent_code":"oceania"},{"land_id":"136","continent_ko":"유럽","continent_code":"europe"},{"land_id":"23","continent_ko":"중동","continent_code":"middleeast"}];

    const date = new Date();
    const currentYear = date.getFullYear();



    return (
        <>
            <AdsComponent dataAdSlot='6007352998' />
            
            <footer className="bg-white rounded-lg shadow dark:bg-gray-900 m-4">
                <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        
                        <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                        
                        
                        {continentCategoryList.map((it,index)=>(
                            <li key={index} >
                                <Link  to={"/continent/"+it.continent_code} 
                                className="mr-4 hover:underline md:mr-6 ">{it.continent_ko}</Link>
                            </li>
                        ))}
                            
                        </ul>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 - {currentYear}
                    <Link to={"/"} className="hover:underline"> 트래블노트</Link>. All Rights Reserved.</span>
                    <span>Email : rinkutoko@gmail.com</span>
                </div>
            </footer>
        </>
    );
}

